import { useIsFocused } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  FlatList,
  LayoutChangeEvent,
  View,
} from 'react-native';

import AutocompleteComponent from '../../components/Common/AutocompleteComponent';
import DrumbeatItem from '../../components/Dashboard/DrumbeatItem';
import DrumbeatLabels from '../../components/Dashboard/DrumbeatLabels';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import useUserDefaultDetails from '../../hooks/useUserDefaultDetails';
import { useStore } from '../../stores';
import {
  assessmentFormScheduleData,
  assessmentScheduleData,
  categoryScheduleData,
} from '../../types';
import MainContainer from '../Common/MainContainer';

type Props = {
  index: number;
};

const DrumbeatSchedule: React.FC<Props> = ({ index }) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const store = useStore();
  const {
    isFromSiteModal,
    setIsFromSiteModal,
    isSiteScheduleChanged,
    setIsSiteScheduleChanged,
    assessmentFormStore,
    assessmentScheduleStore,
    userStore,
    performanceUnitStore,
    siteStore,
    selfVerificationTypeStore,
    isInternetReachable,
    filters,
    setFilters,
  } = store;

  const [assessmentSchedules, setAssessmentSchedules] = useState<
    categoryScheduleData[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rowWidth, setRowWidth] = useState(0);

  const userDefaultDetails = useUserDefaultDetails();
  const isFocused = useIsFocused();

  const [selfVerificationType, setSelfVerificationType] = useState(
    filters?.selfVerificationType || '',
  );
  const [site, setSite] = useState(filters?.site || '');
  const [searchId, setSearchId] = useState<string>('');
  const [performanceUnit, setPerformanceUnit] = useState(
    filters?.performanceUnit || '',
  );
  const theme = useTheme();
  const isFirstRender = useRef(true);
  const isUserPuChanged = useRef(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const initialYear = currentYear.toString();
  const [year, setYear] = useState<string>(initialYear);

  const searchFilters = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    assessmentFormStore.getActiveAssessmentForms().forEach((assessmentForm) => {
      values.push({
        id: `a-${assessmentForm.id.toString()}`,
        text: assessmentForm.name,
      });
    });
    userStore.getUsers({}).forEach((user) => {
      values.push({
        id: `u-${user.id.toString()}`,
        text: user.fullName,
      });
    });
    values.sort((a, b) => a.text.localeCompare(b.text));
    return values;
  }, [assessmentFormStore.assessmentForms.values(), userStore.users.values()]);

  const filteredAssessmentSchedules = useMemo(() => {
    let data = assessmentSchedules;
    if (searchId && searchId.startsWith('a-')) {
      const searchAssessmentFormId = Number(searchId.replace('a-', ''));
      data = data
        .filter((category: categoryScheduleData) =>
          category.data.some(
            (af: assessmentFormScheduleData) =>
              af.assessmentFormId === searchAssessmentFormId,
          ),
        )
        .map((category: categoryScheduleData) => {
          const newCategory = Object.assign({}, category);
          newCategory.data = newCategory.data.filter(
            (af: assessmentFormScheduleData) =>
              af.assessmentFormId === searchAssessmentFormId,
          );
          return newCategory;
        });
    } else if (searchId && searchId.startsWith('u-')) {
      const searchUserId = Number(searchId.replace('u-', ''));
      data = data
        .filter((category: categoryScheduleData) =>
          category.data.some((af: assessmentFormScheduleData) =>
            af.months.some(
              (m: assessmentScheduleData) =>
                m.assignedAssessor && m.assignedAssessor === searchUserId,
            ),
          ),
        )
        .map((category: categoryScheduleData) => {
          return {
            ...category,
            data: category.data.filter((af: assessmentFormScheduleData) =>
              af.months.some(
                (m: assessmentScheduleData) =>
                  m.assignedAssessor && m.assignedAssessor === searchUserId,
              ),
            ),
          };
        })
        .map((category: categoryScheduleData) => {
          return {
            ...category,
            data: category.data.map((af: assessmentFormScheduleData) => {
              return {
                ...af,
                months: af.months.map((m: assessmentScheduleData) => {
                  if (m.assignedAssessor !== searchUserId) {
                    return { month: m.month };
                  } else {
                    return m;
                  }
                }),
              };
            }),
          };
        });
    }
    if (selfVerificationType) {
      data = data
        .filter((category: categoryScheduleData) =>
          category.data.some(
            (af: assessmentFormScheduleData) =>
              af.assessmentFormSvt === Number(selfVerificationType),
          ),
        )
        .map((category: categoryScheduleData) => {
          const newCategory = Object.assign({}, category);
          newCategory.data = newCategory.data.filter(
            (af: assessmentFormScheduleData) =>
              af.assessmentFormSvt === Number(selfVerificationType),
          );
          return newCategory;
        });
    }
    return data;
  }, [assessmentSchedules, searchId, selfVerificationType, year]);

  const performanceUnitFilters = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    performanceUnitStore
      .getActivePerformanceUnits()
      .forEach((performanceUnit) => {
        values.push({
          id: performanceUnit.id.toString(),
          text: performanceUnit.name,
        });
      });
    return values;
  }, [performanceUnitStore.performanceUnits.values()]);

  const siteFilters = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    siteStore.getActiveSites(Number(performanceUnit)).forEach((site) => {
      values.push({
        id: site.id.toString(),
        text: site.name,
      });
    });
    return values;
  }, [siteStore.sites.values(), performanceUnit]);

  const selfVerificationTypeFilters = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    selfVerificationTypeStore
      .getActiveSelfVerificationTypes()
      .forEach((svt) => {
        values.push({
          id: svt.id.toString(),
          text: svt.name,
        });
      });
    return values;
  }, [selfVerificationTypeStore.selfVerificationTypes.values()]);

  useEffect(() => {
    setPerformanceUnit(userDefaultDetails.performanceUnit || '');
    isUserPuChanged.current = true;
  }, [userDefaultDetails.performanceUnit]);

  useEffect(() => {
    if (
      isUserPuChanged.current &&
      siteFilters.find((site) => site.id === userDefaultDetails.site)
    ) {
      setSite(userDefaultDetails.site);
      isUserPuChanged.current = false;
    }
  }, [userDefaultDetails.site, siteFilters]);

  const fetchAssessmentSchedules = async () => {
    if (!isInternetReachable || loading) {
      return;
    }

    if (!site) {
      setAssessmentSchedules([]);
      return;
    }

    setLoading(true);
    const result = await assessmentScheduleStore.fetchAssessmentSchedulesSite(
      Number(site),
      Number(year),
    );
    if (!result.ok) {
      setLoading(false);
      return;
    }
    setAssessmentSchedules(result.extra as categoryScheduleData[]);
    setLoading(false);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    fetchAssessmentSchedules();
  }, [site, searchId, selfVerificationType, year]);

  useEffect(() => {
    if (isFocused && isInternetReachable) {
      if (isSiteScheduleChanged || !isFromSiteModal) {
        fetchAssessmentSchedules();
        setIsSiteScheduleChanged(false);
      } else if (isFromSiteModal) {
        setIsFromSiteModal(false);
      }
    }
  }, [isFocused]);

  useEffect(() => {
    if (filters) {
      setPerformanceUnit(filters.performanceUnit || '');
      setSelfVerificationType(filters.selfVerificationType || '');
    }
  }, [filters]);

  useEffect(() => {
    if (filters) {
      if (filters.site) {
        const siteFilterMatch = siteFilters.find(
          (siteFilter) => siteFilter.id === filters.site,
        );

        if (siteFilterMatch) {
          setSite(filters.site);
        } else {
          setSite('');
        }
      } else {
        setSite('');
      }
    }
  }, [filters?.site, siteFilters]);

  const years = [];
  for (let i = 2021; i <= Number(currentYear) + 1; i++) {
    years.push({
      id: i.toString(),
      text: i.toString(),
    });
  }

  const renderHeader = useCallback(() => {
    return (
      <>
        <View style={styles.header}>
          <Text style={styles.headerText}>
            <Text style={styles.headerYear}>{year}</Text> schedule
          </Text>
        </View>
        <DrumbeatLabels year={year} />
      </>
    );
  }, [year, styles]);

  const renderCategories = useCallback(
    ({ item }: { item: categoryScheduleData }) => (
      <DrumbeatItem
        category={item}
        rowWidth={rowWidth}
        site={site}
        searchId={searchId}
      />
    ),
    [rowWidth, site, searchId, isInternetReachable],
  );

  const renderSeparator = useCallback(
    () => <View style={styles.separator} />,
    [],
  );

  const renderEmptySchedule = () => (
    <View style={styles.emptyContainer}>
      <Text style={styles.emptySchedules}>No schedules found</Text>
    </View>
  );

  const onLayout = (e: LayoutChangeEvent) => {
    const width = e.nativeEvent.layout.width;
    setRowWidth(width);
  };

  return (
    <MainContainer style={{ flex: 1 }} isDrawerScreen paddingTop={0}>
      <View style={styles.filtersContainer} onLayout={onLayout}>
        <View style={styles.filterLeftContainer}>
          <AutocompleteComponent
            placeholder="Search"
            onSelect={(id) => setSearchId(id)}
            data={searchFilters}
            value={searchId}
            marginTop={0}
            disabled={loading}
          />
        </View>
        <View style={styles.filterMiddleContainer}>
          <AutocompleteComponent
            label="Performance Unit"
            placeholder="Select performance unit"
            onSelect={(id) => {
              setFilters({ ...filters, performanceUnit: id, site: '' });
              setPerformanceUnit(id);
              setSite('');
            }}
            data={performanceUnitFilters}
            value={performanceUnit}
            labelHalf
            marginTop={0}
            disabled={loading}
          />
        </View>
        <View style={styles.filterMiddleContainer}>
          <AutocompleteComponent
            label="Site"
            placeholder="Select site"
            onSelect={(id) => {
              setFilters({
                ...filters,
                site: id,
              });
              setSite(id);
            }}
            data={siteFilters}
            value={site}
            initialValue={site}
            labelHalf
            marginTop={0}
            disabled={loading}
          />
        </View>
        <View style={styles.filterMiddleContainer}>
          <AutocompleteComponent
            label="Self-verification Type"
            placeholder="Select self-verification type"
            onSelect={(id) => {
              setFilters({ ...filters, selfVerificationType: id });
              setSelfVerificationType(id);
            }}
            data={selfVerificationTypeFilters}
            value={selfVerificationType}
            labelHalf
            marginTop={0}
            disabled={loading}
          />
        </View>
        <View style={styles.filterRightContainer}>
          <AutocompleteComponent
            label="Year"
            placeholder="Select year"
            onSelect={(id) => setYear(id)}
            data={years}
            value={year}
            labelHalf
            hideCloseIcon
            marginTop={0}
            disabled={loading}
          />
        </View>
      </View>

      {loading && rowWidth > 0 ? (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={theme['bp-green']} />
        </View>
      ) : (
        <FlatList
          data={filteredAssessmentSchedules}
          renderItem={renderCategories}
          contentContainerStyle={{ paddingBottom: 40 }}
          ItemSeparatorComponent={renderSeparator}
          showsHorizontalScrollIndicator={false}
          ListHeaderComponent={renderHeader}
          ListEmptyComponent={renderEmptySchedule}
          stickyHeaderIndices={[0]}
          extraData={[rowWidth, isInternetReachable]}
        />
      )}
    </MainContainer>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    loading: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    topContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 18,
    },
    tabsContainer: {
      flexDirection: 'row',
    },
    header: {
      flex: 1,
      flexDirection: 'row',
      textAlign: 'left',
      textAlignVertical: 'center',
      paddingTop: 12,
      paddingBottom: 14,
      paddingLeft: 6,
      backgroundColor: 'grey-100',
    },
    headerText: {
      fontFamily: 'UniversLTPro_Regular',
      fontWeight: 'bold',
      fontSize: 32,
      color: 'text-dark',
    },
    headerYear: {
      fontFamily: 'UniversLTPro_Regular',
      fontWeight: 'bold',
      fontSize: 32,
      color: 'bp-green',
    },
    legendsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    legend: {
      height: 12,
      width: 12,
      marginLeft: 25,
      marginRight: 8,
    },
    filtersContainer: {
      flexDirection: 'row',
      paddingTop: 6,
    },
    filterLeftContainer: {
      flex: 1,
      paddingRight: 8,
    },
    filterMiddleContainer: {
      flex: 1,
      paddingLeft: 8,
      paddingRight: 8,
    },
    filterRightContainer: {
      flex: 0.7,
      paddingLeft: 8,
    },
    separator: {
      backgroundColor: 'gray-03',
      height: 1,
    },
    emptyContainer: {
      flex: 1,
      justifyContent: 'center',
    },
    emptySchedules: {
      marginTop: 40,
      textAlign: 'center',
      fontFamily: 'UniversLTPro_Regular',
      color: 'text-dark',
    },
  },
  tabletStyle: {
    header: {
      flex: 1,
      flexDirection: 'row',
      textAlign: 'left',
      textAlignVertical: 'center',
      paddingTop: 6,
      paddingBottom: 8,
      paddingLeft: 6,
      backgroundColor: 'grey-100',
    },
    headerText: {
      fontFamily: 'UniversLTPro_Regular',
      fontWeight: 'bold',
      fontSize: 26,
      color: 'text-dark',
    },
    headerYear: {
      fontFamily: 'UniversLTPro_Regular',
      fontWeight: 'bold',
      fontSize: 26,
      color: 'bp-green',
    },
  },
});

export default observer(DrumbeatSchedule);
